import React from 'react';
import { Link } from 'react-router-dom';

import { Flex, Label, LabelTypes } from '@company-ui';
import { StyledCompanyCard, CompanyCardIcon, StyledMetaInfo } from './CompanyCard.style';

interface Author {
  name: string;
  username: string;
}

interface CompanyMetaProps {
  number: number;
  date: string;
  author: Author;
}

interface CompanyCardProps {
  number: number;
  title: string;
  labels: Array<LabelTypes>;
  body: string;
  isRequested: boolean;
  date: string;
  author: Author;
}

export const CompanyMetaInfo: React.FC<CompanyMetaProps> = ({
  number,
  date,
  author
}) => (
  <StyledMetaInfo className="text--light">
    <span className="company__number">#{number}</span> / on {date} by{' '}
    <Link to={`/profiles/${author.username}`}>{author.name}</Link>
  </StyledMetaInfo>
);

const CompanyCard: React.FC<CompanyCardProps> = ({
  number,
  title,
  labels,
  body,
  isRequested,
  date,
  author
}) => {
  return (
    <StyledCompanyCard>
      <CompanyCardIcon isRequested={isRequested} />
      <CompanyMetaInfo number={number} date={date} author={author} />

      <Link to={`/dashboard/companies/${number}`}>
        <h3 className="company__title">{title}</h3>
      </Link>

      {labels.length ? (
        <Flex gap="medium" className="mt-large">
          {labels.map((label, index: number) => (
            <Link key={index} to={`/dashboard/companies/?label=${label}`}>
              <Label type={label}>{label}</Label>
            </Link>
          ))}
        </Flex>
      ) : null}

      <div className="company__body--text mt-large">{body.slice(0, 150)}</div>
    </StyledCompanyCard>
  );
};

export default React.memo(CompanyCard);
