import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContextValues, OnSubmit } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Input, StyledH3Input } from '@company-ui/Form';
import { Button, toast } from '@company-ui';

import AddCompanySchema from './AddCompanySchema';
import Editor from 'components/Editor/Editor';
import DashboardHeader from 'components/DashboardHeader';
import StyledEditor from 'components/Editor/Editor.style';
import AddCompanyWrapper from './AddCompany.style';

import socket from 'utils/socket';
import { addCompany } from 'store/ducks/companies';
import { StoreState } from 'store';

const AddCompany: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    setValue
  }: FormContextValues<Record<string, any>> = useForm({
    validationSchema: AddCompanySchema
  });
  const markdown = watch('body');
  const handleMarkdown = (e: any) => {
    setValue('body', e.target.value);
  };

  const [isLoading, error] = useSelector((state: StoreState) => [
    state.loading['companies/ADD_Company'],
    state.error['companies/ADD_Company']
  ]);
  const onSubmit = async (data: { title: string; body: string }) => {
    dispatch(addCompany(data)).then(() => {
      reset();
      setValue('body', '');
      history.push('/dashboard/companies');
      socket.emit('send-notification', { message: 'New company' });
      toast.success('New company added!');
    });
  };

  error && toast.error(error);
  return (
    <AddCompanyWrapper>
      <DashboardHeader>
        <h1>Submit new company</h1>
      </DashboardHeader>

      <form onSubmit={handleSubmit(onSubmit as any)}>
        <StyledEditor>
          <StyledH3Input className="company__edit-title">
            <Input
              autoComplete="off"
              name="title"
              type="text"
              icon="edit"
              placeholder="Enter Title"
              errors={errors}
              inputRef={register({ required: 'Title is required' })}
            />
          </StyledH3Input>
          <Editor
            handleMarkdown={handleMarkdown}
            markdown={markdown}
            errors={errors}
            inputRef={register}
          />

          <Button
            isLoading={isLoading}
            type="submit"
            className="company__button"
            icon="plus"
          >
            Submit
          </Button>
        </StyledEditor>
      </form>
    </AddCompanyWrapper>
  );
};

export default AddCompany;
