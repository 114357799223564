import React, { useEffect } from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

import { formatDate } from 'utils';
import {
  Flex,
  Button,
  Loading,
  Label,
  LabelTypes,
  Illustration
} from '@company-ui';

import DashboardHeader from 'components/DashboardHeader';
import CompanyCard from 'components/CompanyCard/CompanyCard';
import { fetchCompanies } from 'store/ducks/companies';
import { StoreState } from 'store';

const breakpointColumns = {
  default: 3,
  1440: 3,
  1024: 2,
  768: 1
};

const CompaniesWrapper = styled.section`
  margin-top: 0;
`;

const Companies: React.FC = () => {
  let query = useQuery();
  const dispatch = useDispatch();
  const companies = useSelector((state: StoreState) => state.companies);
  const [isLoading, error] = useSelector((state: StoreState) => [
    state.loading['companies/FETCH_COMPANIES'],
    state.error['companies/FETCH_COMPANIES']
  ]);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  const renderCompanies = () => {
    if (error) {
      return (
        <Illustration
          message="Something went wrong while loading the data"
          type="error"
        />
      );
    }

    if (companies.length === 0 && !isLoading) return <Illustration type="empty" />;

    // setting is loading in JSX because
    // early exiting prevents loading & showing data at the same time
    return (
      <>
        {isLoading && <Loading />}
        <Masonry
          breakpointCols={breakpointColumns}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {companies
            ?.filter((company: any) => {
              const labelName = query.get('label');
              const status = query.get('status');

              if (!labelName && !status) return company.isRequested;
              // check for matching label or isRequested status
              return company.labels.includes(labelName) || (status && !company.isRequested);
            })
            .map((company: any) => {
              return (
                <CompanyCard
                  key={company.id}
                  title={company.title}
                  number={company.companyId}
                  labels={company.labels}
                  body={company.body}
                  isRequested={company.isRequested}
                  date={formatDate(company.date_requested)}
                  author={company.author}
                />
              );
            })}
        </Masonry>
      </>
    );
  };

  return (
    <CompaniesWrapper>
      <DashboardHeader>
        <Flex align="center">
          <h1>{query.get('status') ? 'Reviewed' : 'Requests'}</h1>
          <Button
            as={NavLink}
            to="/dashboard/new-company"
            style={{ marginLeft: 20 }}
            icon="plus"
          >
            Add
          </Button>
        </Flex>
        {query.get('label') && (
          <>
            <br />
            <Flex align="center">
              <p>Filter: &nbsp;</p>
              <Label
                style={{ margin: 0 }}
                type={query.get('label') as LabelTypes}
              >
                {query.get('label')}
              </Label>
            </Flex>
          </>
        )}
      </DashboardHeader>

      {renderCompanies()}
    </CompaniesWrapper>
  );
};

export default Companies;
