import useFetch from "hooks/useFetch";
import { useState, useEffect } from "react";

const useSuggestion = (url: string, prop: string[]) => {
  const [suggestions] = useFetch(url, { cache: true });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!suggestions) return;
    const isCompany = prop[1] === 'companyId';
    const suggestionsArray = suggestions.data.map((suggestions: any) => {
      let display = suggestions[prop[0]];
      if (isCompany) {
        // if it's a company then append the #1 (companyId) to the display
        display = suggestions[prop[0]] + ' #' + suggestions[prop[1]];
      }
      return { display, id: suggestions[prop[1]] };
    });
    setData(suggestionsArray);
  }, [suggestions]);

  return data;
};


export default useSuggestion;
