import React from 'react';
import { Button } from '@company-ui';

const ReviewRequestButton = ({
  onRequestToggle,
  isRequested,
  isLoading
}: {
  onRequestToggle: any;
  isRequested: boolean;
  isLoading: boolean;
}) => (
  <Button
    isLoading={isLoading}
    variant={isRequested ? 'success' : 'danger'}
    icon={isRequested ? 'smile' : 'history'}
    onClick={(e: any) => {
      e.preventDefault();
      onRequestToggle(isRequested ? 'reviewed' : 'requested');
    }}
  >
    {isRequested ? 'Review' : 'Request update'}
  </Button>
);

export default ReviewRequestButton;
