import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from 'utils';
import { Flex, Avatar, CircleIcon } from '@company-ui';

import { AuthorProps } from './SingleCompany';
import { StyledMetaInfo } from 'components/CompanyCard/CompanyCard.style';

interface MetaProps {
  isRequested?: boolean;
  date: string;
  author: AuthorProps;
  commentsCount?: number;
  showAvatar?: boolean;
}

const MetaInfo: React.FC<MetaProps> = ({
  isRequested,
  date,
  author,
  commentsCount,
  showAvatar
}) => (
  <StyledMetaInfo>
    <Flex gap="medium" align="center" nowrap>
      <CircleIcon
        size="30px"
        variant={isRequested ? 'danger' : 'success'}
        icon={isRequested ? 'tag' : 'check'}
      />
      {showAvatar && (
        <Avatar
          width="30px"
          height="30px"
          size={45}
          username={author.username}
        />
      )}
      <span>
        {isRequested ? 'Requested' : 'Reviewed'} by
        <Link className="text--medium" to={`/profiles/${author.username}`}>
          &nbsp;{author.name}&nbsp;
        </Link>
        on {formatDate(date)}
        {commentsCount ? <span> / {commentsCount} comments</span> : null}
      </span>
    </Flex>
  </StyledMetaInfo>
);

export default React.memo(MetaInfo);
