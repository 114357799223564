import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLabel } from '@company-ui';

const StyledNotificationIcon = styled(StyledLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  margin-right: 15px;
`;

const NotificationIcon: React.FC<{ type: string; companyStatus?: string }> = ({
  type,
  companyStatus
}) => {
  const icons: any = {
    COMMENTED: ['comment', 'secondary'],
    MENTIONED: ['at', 'secondary'],
    // companyStatus special case,
    // change icon and color depending on 'reviewed' or 'requested' state
    COMPANY_STATUS: [
      companyStatus === 'requested' ? 'tag' : 'check',
      companyStatus === 'requested' ? 'danger' : 'success'
    ],
    REFERENCED: ['retweet', 'success'],
    NEW_COMPANY: ['tag', 'danger']
  };
  return (
    <StyledNotificationIcon variant={icons[type][1]}>
      <FontAwesomeIcon className="faIcon" icon={icons[type][0]} />
    </StyledNotificationIcon>
  );
};

export default NotificationIcon;
