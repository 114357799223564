import { ApiAction } from 'store/middlewares/apiMiddleware';
import { createAPIAction } from 'store/helpers';

// action
export const API = 'API';
export const FETCH_COMPANIES = createAPIAction('companies/FETCH_COMPANIES');
export const ADD_COMPANY = createAPIAction('companies/ADD_COMPANY');

const DEFAULT_STATE = <any[]>[];

// reducers
const reducer = (state = DEFAULT_STATE, action: any) => {
  switch (action.type) {
    case FETCH_COMPANIES.SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};

export default reducer;

// side effects
export const fetchCompanies = (): ApiAction => ({
  type: API,
  payload: {
    method: 'GET',
    url: `/api/companies/`,
  },
  onRequest: FETCH_COMPANIES.REQUEST,
  onSuccess: FETCH_COMPANIES.SUCCESS,
  onFailure: FETCH_COMPANIES.FAILURE,
});

export const addCompany = (formData: {
  title: string;
  body: string;
}): ApiAction => ({
  type: API,
  payload: {
    method: 'POST',
    url: `/api/companies/`,
    formData,
  },
  onRequest: ADD_COMPANY.REQUEST,
  onSuccess: ADD_COMPANY.SUCCESS,
  onFailure: ADD_COMPANY.FAILURE,
});
