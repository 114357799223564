import React from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { useParams } from 'react-router-dom';

import { formatDate } from 'utils';
import useFetch from 'hooks/useFetch';
import { Illustration, Loading } from '@company-ui';

import DashboardHeader from 'components/DashboardHeader';
import CompanyCard from 'components/CompanyCard/CompanyCard';
import UserInfo from './UserInfo';

const breakpointColumns = {
  default: 3,
  1440: 3,
  1024: 2,
  768: 1
};

const ProfileWrapper = styled.section`
  .user__companies {
    margin-top: ${p => p.theme.spacings.top * 2}px;
  }
`;

const Profile = () => {
  const { username } = useParams();
  const [userData, userLoading, userError] = useFetch(`/api/user/${username}`);
  const [companyData, companiesLoading, companiesError] = useFetch(
    `/api/user/${username}/companies`
  );
  const [commentsCountData] = useFetch(`/api/user/${username}/comments/count`);

  const isLoading = userLoading || companiesLoading;
  const error = userError || companiesError;
  const user = userData?.data;
  const companies = companyData?.data || [];
  const commentCount = commentsCountData?.data?.counts;

  const renderUserInfo = () => {
    if (isLoading) return <Loading />;
    if (error) {
      return (
        <Illustration
          type="error"
          message="Something went wrong while loading the data"
        />
      );
    }
    return (
      <>
        {user && (
          <UserInfo
            user={user}
            totalComments={commentCount}
            totalCompanies={companies?.length}
          />
        )}
        <section className="user__companies">
          <h3>Companies requested by {username}</h3>
          <br />
          <br />
          {companies.length > 0 ? (
            <Masonry
              breakpointCols={breakpointColumns}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {companies.map((company: any) => (
                <CompanyCard
                  key={company.id}
                  title={company.title}
                  number={company.companyId}
                  labels={company.labels}
                  body={company.body}
                  isRequested={company.isRequested}
                  date={formatDate(company.date_requested)}
                  author={company.author}
                />
              ))}
            </Masonry>
          ) : (
            <Illustration
              type="empty"
              message={`No companies requested by ${username}`}
            />
          )}
        </section>
      </>
    );
  };

  return (
    <ProfileWrapper>
      <DashboardHeader>
        <h1>User</h1>
      </DashboardHeader>
      {renderUserInfo()}
    </ProfileWrapper>
  );
};

export default Profile;
