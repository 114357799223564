import React from 'react';
import { NavLink } from 'react-router-dom';

const CompanyLinkTitle: React.FC<{ companyId: string; title: string }> = ({
  companyId,
  title
}) => (
  <NavLink to={`/dashboard/companies/${companyId}`}>
    #{companyId} <small>({title})</small>
  </NavLink>
);

const Commented: React.FC = ({ byUser, onCompany }: any) => {
  return (
    <>
      <NavLink to={`/profiles/${byUser?.username}`}>
        @{byUser?.username}
      </NavLink>
      <span> commented on </span>
      <CompanyLinkTitle companyId={onCompany?.companyId} title={onCompany?.title} />
    </>
  );
};

const Referenced: React.FC = ({ byUser, fromCompany, references }: any) => {
  const referenceSlice = references?.slice(0, 2);
  const remaining = references?.length - referenceSlice?.length;

  const refs = referenceSlice?.map((ref: any, index: number) => (
    <>
      {references.length === 2 && index > 0 && ' & '}
      <CompanyLinkTitle companyId={ref.companyId} title={ref.title} key={ref.id} />
    </>
  ));
  return (
    <>
      <span>
        {refs}
        {remaining > 0 && <span> & {remaining} more companies</span>}
        &nbsp; referenced in &nbsp;
      </span>
      <CompanyLinkTitle companyId={fromCompany?.companyId} title={fromCompany?.title} />
      &nbsp;by{' '}
      <NavLink to={`/profiles/${byUser?.username}`}>
        @{byUser?.username}
      </NavLink>
    </>
  );
};

const NewCompany: React.FC = ({ byUser, onCompany }: any) => (
  <>
    <NavLink to={`/profiles/${byUser?.username}`}>@{byUser?.username}</NavLink>
    <span> requested a new company </span>
    <CompanyLinkTitle companyId={onCompany?.companyId} title={onCompany?.title} />
  </>
);

const CompanyStatus: React.FC = ({ byUser, onCompany, company_status }: any) => (
  <>
    <CompanyLinkTitle companyId={onCompany?.companyId} title={onCompany?.title} />
    <span> {company_status} by </span>
    <NavLink to={`/profiles/${byUser?.username}`}>@{byUser?.username}</NavLink>
  </>
);

const Mentioned: React.FC = ({ byUser, onCompany, mentions }: any) => {
  return (
    <>
      <NavLink to={`/profiles/${byUser?.username}`}>
        @{byUser?.username}{' '}
      </NavLink>
      <span>
        mentioned
        {mentions.map((username: string, index: number) => {
          return (
            <>
              {mentions.length > 2 && index > mentions.length - 2 && '&'}
              <NavLink to={`/profiles/${username}`}> @{username} </NavLink>
            </>
          );
        })}
      </span>
      <span>
        at <CompanyLinkTitle companyId={onCompany?.companyId} title={onCompany?.title} />
      </span>
    </>
  );
};

const notification_switch: any = {
  COMMENTED: Commented,
  REFERENCED: Referenced,
  NEW_COMPANY: NewCompany,
  COMPANY_STATUS: CompanyStatus,
  MENTIONED: Mentioned
};

export default notification_switch;
